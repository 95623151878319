import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconAndVenison = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/venisonscramble_large.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon and Venison Scramble Recipe"
        metaDescription="We know our thick-cut, smoky bacon is a classic breakfast flavor, but track down some venison if you’re on the hunt for a new adventure. View the full recipe. "
        metaKeywords="bacon scramble recipe"
    >
        <meta property="og:image" content={imageBaconAndVenison} />
        <link rel="image_src" href={imageBaconAndVenison} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconAndVenison} alt="Bacon and Venison Scramble"></img>
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon and Venison Scramble</h1>

                            <div class="recipe-content">
                                <p>
                                    We know our thick-cut, smoky bacon is classic breakfast flavor, but track down some venison if you&rsquo;re on the hunt for a new addition to your morning meal. The lean meat is a great match for our full, bold flavor.<br />
                                    &nbsp;<br />
                                </p>
                                <p>
                                    Venison sausage is easy enough to find at your local butcher shop, grocery store, or online retailer. Once you&rsquo;ve got your bacon and sausage cooked up, add eggs, pepper, onions and cheese to make for a hearty breakfast to help fill you up and fuel your day.
</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        10&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                10 <span class="uom"></span> slices Wright® Brand Naturally Hickory Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3/4 <span class="uom">Cup</span> venison sausage, raw
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/2 <span class="uom"></span> yellow onion
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom"></span> poblano peppers, seeded and diced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> eggs, beaten
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3/4 <span class="uom">Cup</span> shredded cheddar cheese
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Tablespoons</span> scallions, thinly sliced
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Heat up a non-stick or cast iron skillet to medium heat. Gently place Wright® Brand Naturally Hickory Smoked Bacon in the pan and cook until brown and crispy.

                </p>
                            <p>
                                2. Remove bacon from pan and place onto paper towel to drain excess grease. Set aside. Have a taste if you like.

                </p>
                            <p>
                                3. In the same pan, add the venison sausage. Fully cook until brown. Once cooked, place sausage on paper towel to drain excess grease.

                </p>
                            <p>
                                4. Your skillet should be pretty hot by now. Add onions and peppers and cook until onions have become translucent. The veggies will soak up some of those bacon and venison flavors left behind in the pan.

                </p>
                            <p>
                                5. …but that doesn’t mean we don’t need the bacon and sausage. Add them back into the pan, breaking the bacon as you stir all ingredients together.

                </p>
                            <p>
                                6. Add eggs and stir periodically until eggs have cooked.

                </p>
                            <p>
                                7. Add half the cheddar cheese and half the scallions over the top. Once the cheese melts, it’s done.

                </p>
                            <p>
                                8. Serve four even portions of scramble. Top with the remaining cheese and scallions.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </Layout>
)

export default RecipePage
